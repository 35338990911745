<template>
  <el-row type="flex" justify="center" align="middle" class="login-row">
    <el-col :xs="20" :sm="16" :md="12" :lg="6">
      <el-alert v-if="showIncorrectPassword" :closable="false" type="error">
        <div>
          <i class="fas fa-exclamation-circle action-icon-withour-pointer" />
          {{ $t("login.incorrect_mail_or_password1") }}
          <b
            :underline="false"
            class="forget-password"
            type="success"
            @click="toForgetPassword"
            >{{ $t("login.forget_password") }}</b
          >
        </div>
      </el-alert>
      <el-tabs type="border-card" v-model="activeTab" class="mt-3">
        <el-tab-pane :label="$t('login.login')" name="login">
          <el-form
            class="login-form"
            :rules="rules"
            :model="model"
            ref="form"
            :validate-on-rule-change="false"
            @keyup.enter.native="handleLogin()"
          >
            <el-form-item prop="email">
              <el-input
                v-model="model.email"
                :placeholder="$t('login.email')"
                prefix-icon="el-icon-user"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="model.password"
                :placeholder="$t('login.password')"
                type="password"
                prefix-icon="el-icon-lock"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-row>
                <el-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                  <el-link
                    :underline="false"
                    class="forget-password"
                    type="success"
                    @click="toForgetPassword"
                    >{{ $t("login.forget_password") }}</el-link
                  >
                </el-col>
                <el-col :sm="{ span: 15, offset: 1 }" align="right">
                  <el-button type="primary" block @click="handleLogin()">
                    {{ $t("login.login") }}
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          :label="$t('login.register')"
          name="register"
        >
          <Register />
        </el-tab-pane>
      </el-tabs>
    </el-col>
  </el-row>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import lineLoginApis from "@/apis/lineLogin";
/*eslint-enable */
import formMixin from "@/mixins/form";
import Register from "@/views/Register";

export default {
  metaInfo() {
    return {
      title: "Login - " + this.CompanyName
    };
  },

  components: {
    Register
  },

  mixins: [formMixin],

  props: [],
  data: () => {
    return {
      model: {
        email: "",
        password: ""
      },
      showIncorrectPassword: false,
      lineLogin: "",
      activeTab: "login"
    };
  },
  computed: {
    rules() {
      const checkEmail = (rule, value, callback) => {
        // 验证邮箱的正则表达式
        const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        if (regEmail.test(value)) {
          callback(new Error(this.$t("register.please_valid_email")));
        } else {
          callback();
        }
      };
      return {
        email: [
          // { validator: checkEmail, trigger: "blur" },
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          },
          {
            type: "email",
            message: this.$t("register.please_valid_email"),
            trigger: ["blur", "change"]
          }
        ],
        password: [
          {
            required: true,
            message: this.$t("register.please_input_password"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {},

  mounted() {
    if (this.$route.name === "Register") {
      this.activeTab = "register";
    }
    if (this.$route.query.email) {
      this.model.email = this.$route.query.email;
    }
  },

  methods: {
    ...mapActions("user", ["login"]),

    register() {
      this.$router.push({ name: "Register" });
    },
    async handleLogin() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }
      try {
        await this.login(this.model);
      } catch (e) {
        if (e.data.error === "Unauthorized") {
          this.showIncorrectPassword = true;
          return;
        }
        return;
      }
      await this.$message({
        message: this.$t("message.login_success"),
        type: "success"
      });
      await this.$store.dispatch("user/getProfile");

      let nextRoutePath = localStorage.getItem("lastAccessRoutePath");
      localStorage.setItem("lastAccessRoutePath", "");
      if (!nextRoutePath || nextRoutePath == "/home") {
        this.$router.replace({ name: "SATList" });
      } else {
        this.$router.replace({ path: nextRoutePath });
      }
    },
    toForgetPassword() {
      let email = undefined;
      if (this.model.email !== "") {
        email = this.model.email;
      }
      this.$router.push({
        name: "ForgetPassword",
        query: {
          email: email
        }
      });
    },
    async fetchLineLoginAuthURL() {
      let url = await this.$router.resolve({
        name: "LineLogin"
      });

      let lineLoginAuthUrl = await lineLoginApis.getAuthURL({
        redirect_uri: window.location.origin + url.href
      });

      this.lineLogin = lineLoginAuthUrl.url;
    }
  }
};
</script>

<style scoped>
.login-row {
  position: relative;
  top: 100px;
}

::v-deep .el-alert .el-alert__description {
  font-size: 13px;
}
.el-card /deep/ .el-card__header {
  background: var(--themeColor);
}

.clearfix {
  color: white;
}

/* .forget-password {
  font-size: 13px;
} */
.forget-password {
  font-size: 13px;
  color: var(--themeColor);
  cursor: pointer;
}

li a {
  text-decoration: none;
}

.el-button {
  outline: none;
}

::v-deep .el-form-item {
  margin-bottom: 1.2rem;
}
</style>
